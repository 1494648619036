@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./reset.css";
@import "./index.css";

body {
    font-family: "Roboto", sans-serif;
}
